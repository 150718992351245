import { Route, Routes } from "react-router-dom"
import "./App.css"
import { Layout } from "./components/Layout"
// context
import { ThemeProvider } from "./context/useTheme"
import { AuthProvider } from "./context/useAuth"
import { PrivateRoute } from "./utils/Router/PrivateRoute"
// end context
// pages
import { ErrorPage } from "./pages/ErrorPage"
import { Dashboard } from "./pages/Dashboard"
import { Admin } from "./pages/Admin"
import { CallAnalysis } from "./pages/CallAnalysis"
import { Recordings } from "./pages/Recordings"
import { Profile } from "./pages/Settings/Profile"
import { Account } from "./pages/Settings/Account"
import { Redirect } from "./pages/Redirect"
import { ViewLocation } from "./pages/Admin/ViewLocation"
import { EditLocation } from "./pages/Admin/EditLocation"
import { ShareAnalysis } from "./pages/ShareAnalysis"
// auth
import { Signin } from "./pages/Signin"
import { Signup } from "./pages/Signup"
import { ForgotPassword } from "./pages/ForgotPassword"
import { ResetPassword } from "./pages/ResetPassword"
import { InsertKey } from "./pages/InsertKey"
import { SyncCRM } from "./pages/SyncCRM"
// for manager only
import { ManagerUsers } from "./pages/Manager/Users"
import { ManagerDashboard } from "./pages/Manager/Dashboard"
// end manager pages

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/insert-key" element={<InsertKey />} />
          <Route path="/sync-crm" element={<SyncCRM />} />
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/redirect"
            element={
              <Layout>
                <Redirect />
              </Layout>
            }
          />
          <Route
            path="/main/dashboard"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/admin"
            element={
              <PrivateRoute>
                <Layout>
                  <Admin />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/admin/location/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <ViewLocation />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/admin/location/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditLocation />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/call-analysis/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <CallAnalysis />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/recordings"
            element={
              <PrivateRoute>
                <Layout>
                  <Recordings />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/settings/profile"
            element={
              <PrivateRoute>
                <Layout>
                  <Profile />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/settings/account"
            element={
              <PrivateRoute>
                <Layout>
                  <Account />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* start manager page */}
          <Route
            path="/main/manager/dashboard"
            element={
              <PrivateRoute>
                <Layout>
                  <ManagerDashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/main/manager/users"
            element={
              <PrivateRoute>
                <Layout>
                  <ManagerUsers />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* end manager page */}
          <Route path="/share/:link" element={<ShareAnalysis />} />
        </Routes>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
