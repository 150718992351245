import { Fragment, useState, useEffect } from "react"
import { useLocation } from "react-router"
import { Link, NavLink } from "react-router-dom"
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react"
import {
  Bars3BottomLeftIcon,
  ClockIcon,
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
  XMarkIcon,
  HomeIcon,
  Cog6ToothIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline"
import { UserIcon, ChevronRightIcon, BellIcon } from "@heroicons/react/24/solid"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { logos } from "../../assets"
import { ThemeSwitcher } from "../ThemeSwitcher"
import { BASE_URL } from "../../utils/Config"
import { useAuth } from "../../context/useAuth"
import axiosInstance from "../../utils/AxiosInstance"

const userNavigation = [{ name: "yourProfile", href: "/main/settings/profile" }]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Layout = ({ children }) => {
  const auth = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation("translation")
  const [navigation, setNavigation] = useState([])
  const id = localStorage.getItem("id")
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/users/userId/${id}`,
      headers: {
        "Authorization": `Token ${token}`,
      },
    })
      .then((res) => {
        auth.saveUser(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [id])

  const settingSidebar = [
    { name: "profile", href: "/main/settings/profile" },
    { name: "account", href: "/main/settings/account" },
  ]

  const managerSidebar = [
    { name: "dashboard", href: "/main/manager/dashboard" },
    { name: "users", href: "/main/manager/users" },
  ]

  useEffect(() => {
    if (auth.account.manager) {
      if (auth.account.role === "admin") {
        setNavigation([
          {
            name: "dashboard",
            href: "/main/dashboard",
            icon: HomeIcon,
            current: true,
          },
          {
            name: "recordings",
            href: "/main/recordings",
            icon: ClockIcon,
            current: false,
          },
          {
            name: "admin",
            href: "/main/admin",
            icon: UserCircleIcon,
            current: false,
          },
          {
            name: "setting",
            icon: Cog6ToothIcon,
            current: false,
            children: settingSidebar,
          },
          {
            name: "manager",
            icon: AdjustmentsHorizontalIcon,
            current: false,
            children: managerSidebar,
          },
        ])
      } else {
        setNavigation([
          {
            name: "dashboard",
            href: "/main/dashboard",
            icon: HomeIcon,
            current: true,
          },
          {
            name: "recordings",
            href: "/main/recordings",
            icon: ClockIcon,
            current: false,
          },
          {
            name: "setting",
            icon: Cog6ToothIcon,
            current: false,
            children: settingSidebar,
          },
          {
            name: "manager",
            icon: AdjustmentsHorizontalIcon,
            current: false,
            children: managerSidebar,
          },
        ])
      }
    } else {
      if (auth.account.role === "admin") {
        setNavigation([
          {
            name: "dashboard",
            href: "/main/dashboard",
            icon: HomeIcon,
            current: true,
          },
          {
            name: "recordings",
            href: "/main/recordings",
            icon: ClockIcon,
            current: false,
          },
          {
            name: "admin",
            href: "/main/admin",
            icon: UserCircleIcon,
            current: false,
          },
          {
            name: "setting",
            icon: Cog6ToothIcon,
            current: false,
            children: settingSidebar,
          },
        ])
      } else {
        setNavigation([
          {
            name: "dashboard",
            href: "/main/dashboard",
            icon: HomeIcon,
            current: true,
          },
          {
            name: "recordings",
            href: "/main/recordings",
            icon: ClockIcon,
            current: false,
          },
          {
            name: "setting",
            icon: Cog6ToothIcon,
            current: false,
            children: settingSidebar,
          },
        ])
      }
    }
    // eslint-disable-next-line
  }, [auth])

  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4 justify-center">
                  <img
                    className="h-8 w-auto cursor-pointer"
                    src={logos.Logo}
                    alt="Logo"
                    onClick={(e) => {
                      e.preventDefault()
                      if (localStorage.getItem("accessToken")) {
                        navigate("/main/dashboard")
                      } else {
                        navigate("/")
                      }
                    }}
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="flex flex-1 flex-col px-2 py-4">
                    <ul className="space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          {!item.children ? (
                            <NavLink
                              to={item.href}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? "bg-gray-900 text-white"
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md"
                                )
                              }
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {t(`sidebar.${item.name}`)}
                            </NavLink>
                          ) : (
                            <Disclosure
                              as="div"
                              defaultOpen={item.children.some((child) =>
                                location.pathname.startsWith(child.href)
                              )}
                            >
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      item.current
                                        ? "bg-gray-900"
                                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                      "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-300"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    {t(`sidebar.${item.name}`)}
                                    <ChevronRightIcon
                                      className={classNames(
                                        open
                                          ? "rotate-90 text-white"
                                          : "text-gray-300",
                                        "ml-auto h-5 w-5 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  {
                                    <Disclosure.Panel
                                      as="ul"
                                      className="mt-1 px-2"
                                    >
                                      {item.children.map((subItem) => (
                                        <li key={subItem.name}>
                                          {/* 44px */}
                                          <NavLink
                                            to={subItem.href}
                                            className={({ isActive }) =>
                                              classNames(
                                                isActive
                                                  ? "bg-gray-900"
                                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-300"
                                              )
                                            }
                                          >
                                            {t(`sidebar.${subItem.name}`)}
                                          </NavLink>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  }
                                </>
                              )}
                            </Disclosure>
                          )}
                        </li>
                      ))}
                      <li>
                        <NavLink
                          className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md"
                          onClick={(e) => {
                            e.preventDefault()
                            localStorage.removeItem("accessToken")
                            navigate("/signin")
                          }}
                        >
                          <ArrowLeftOnRectangleIcon
                            className="h-6 w-6 shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          Sign Out
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col">
          <div className="flex h-16 flex-shrink-0 items-center px-4 justify-center shadow bg-gray-800">
            <img
              className="h-8 w-auto cursor-pointer"
              src={logos.Logo}
              alt="Logo"
              onClick={(e) => {
                e.preventDefault()
                if (localStorage.getItem("accessToken")) {
                  navigate("/main/dashboard")
                } else {
                  navigate("/")
                }
              }}
            />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex flex-1 flex-col px-2 py-4 bg-gray-800 mt-6">
              <ul className="space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {!item.children ? (
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md"
                          )
                        }
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {t(`sidebar.${item.name}`)}
                      </NavLink>
                    ) : (
                      <Disclosure
                        as="div"
                        defaultOpen={item.children.some((child) =>
                          location.pathname.startsWith(child.href)
                        )}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "bg-gray-900"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-300"
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {t(`sidebar.${item.name}`)}
                              <ChevronRightIcon
                                className={classNames(
                                  open
                                    ? "rotate-90 text-white"
                                    : "text-gray-300",
                                  "ml-auto h-5 w-5 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            {
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    {/* 44px */}
                                    <NavLink
                                      to={subItem.href}
                                      className={({ isActive }) =>
                                        classNames(
                                          isActive
                                            ? "bg-gray-900"
                                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                          "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-300"
                                        )
                                      }
                                    >
                                      {t(`sidebar.${subItem.name}`)}
                                    </NavLink>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            }
                          </>
                        )}
                      </Disclosure>
                    )}
                  </li>
                ))}
                {/* text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md */}
                <li>
                  <NavLink
                    className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md"
                    onClick={(e) => {
                      e.preventDefault()
                      localStorage.removeItem("accessToken")
                      navigate("/signin")
                    }}
                  >
                    <ArrowLeftOnRectangleIcon
                      className="h-6 w-6 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    Sign Out
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:pl-64">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white dark:bg-gray-800 shadow">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1"></div>
            <div className="ml-4 flex items-center md:ml-6">
              <ThemeSwitcher />
              <button
                type="button"
                className="p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-sky-500 relative"
                onClick={() => {
                  navigate("/notifications")
                }}
              >
                <span className="sr-only">View notifications</span>
                <BellIcon
                  className="h-6 w-6 dark:text-gray-400 dark:hover:text-white text-gray-400 hover:text-gray-500"
                  aria-hidden="true"
                />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="relative lg:ml-0 ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center text-sm">
                    <span className="sr-only">Open user menu</span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="mx-4 text-sm font-semibold leading-6 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
                        aria-hidden="true"
                      >
                        {auth.account && (
                          <Fragment>
                            {auth.account.firstname} {auth.account.lastname}
                          </Fragment>
                        )}
                      </span>
                    </span>
                    <span
                      className={classNames(
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-gray-800 bg-gray-400 hover:bg-gray-500 dark:bg-gray-300 dark:hover:bg-white"
                      )}
                    >
                      <UserIcon
                        className="h-5 w-5 text-white dark:text-gray-900"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-gray-700">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active
                                ? "bg-gray-100 dark:bg-gray-900 dark:text-gray-100"
                                : "",
                              "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 border-b-2 dark:border-b-gray-700"
                            )}
                          >
                            {t(`navbar.${item.name}`)}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100"
                              : "text-gray-700 dark:text-gray-300",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={() => {
                            localStorage.removeItem("accessToken")
                            navigate("/signin")
                          }}
                        >
                          {t("navbar.logout")}
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="flex-1 min-h-[calc(100vh-64px)]">
          <div className="py-6">{children}</div>
        </main>
      </div>
    </div>
  )
}
