import React, { useState, Fragment, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { BASE_URL } from "../../utils/Config"
import { logos } from "../../assets"

export const ForgotPassword = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  // for modal
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [isSuccess, setIsSuccess] = useState(true)
  // end modal

  const [email, setEmail] = useState("")

  const Signin = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `${BASE_URL}/users/forgotPassword`,
        {
          email: email,
        }
      )
      console.log(response.data)
      setIsSuccess(true)
      setTitle("Success")
      setMessage("Please check your email to reset your password again.")
      setOpen(true)

      // navigate("/signin")
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
      setTitle("Wrong email")
      setMessage("Please check your email again.")
      setOpen(true)
    }
  }
  return (
    <>
      <div className="flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-800">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto cursor-pointer"
              src={logos.DarkLogo}
              alt="NABL"
              onClick={() => {
                navigate("/")
              }}
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("forgotPassword.resetPassword")}
            </h2>
            <p className="mt-3 text-center text-gray-600 dark:text-gray-300">
              {t("forgotPassword.description")}
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={Signin}>
            <div className="-space-y-px rounded-md shadow-sm">
              <input
                type="email"
                required
                className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>

            <div>
              <button className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                {t("forgotPassword.resetPassword")}
              </button>
            </div>
          </form>
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100"
                          >
                            {title}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 dark:text-gray-300">{message}</p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        {isSuccess ? (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => setOpen(false)}
                          >
                            {t("modal.close")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => setOpen(false)}
                          >
                            {t("modal.close")}
                          </button>
                        )}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  )
}
