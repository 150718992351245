import React, { useState, useEffect } from "react"
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid"
import { useTheme } from "../../context/useTheme"

export const ThemeSwitcher = () => {
  const savedTheme = useTheme()
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true"
    setDarkMode(isDarkMode)
  }, [])

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode)
    localStorage.setItem("darkMode", darkMode)
    savedTheme.saveTheme(darkMode)
    // eslint-disable-next-line
  }, [darkMode])

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode)
  }

  return (
    <div className="p-1">
      {darkMode ? (
        <MoonIcon
          className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer"
          onClick={toggleDarkMode}
        />
      ) : (
        <SunIcon
          className="w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer"
          onClick={toggleDarkMode}
        />
      )}
    </div>
  )
}
