import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axiosInstance from "../../utils/AxiosInstance"
import { BASE_URL } from "../../utils/Config"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const EditLocation = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [location, setLocation] = useState({})
  const [locationName, setLocationName] = useState("")

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/locations/location`,
      params: {
        id: params.id,
      },
    })
      .then((res) => {
        setLocation(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [params.id])

  return (
    <div>
      <div className="flex justify-between px-4 mb-4">
        <p className="text-xl text-gray-900 dark:text-gray-100 font-semibold">
          Location name
        </p>
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => navigate("/main/admin")}>
          Back
        </button>
      </div>
      <div className="bg-white dark:bg-gray-800 p-4 mx-4 rounded shadow">
        <input
          type="email"
          name="email"
          id="email"
          className="col-span-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-4"
          defaultValue={location.name}
          onChange={(e) => setLocationName(e.target.value)}
        />
        <button
          className={classNames(
            "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:max-w-max",
            locationName === ""
              ? "bg-indigo-400 cursor-default"
              : "bg-indigo-600 hover:bg-indigo-500"
          )}
          disabled={locationName === ""}
          onClick={() => {
            const data = {
              id: params.id,
              name: locationName,
            }
            axiosInstance({
              method: "put",
              url: `${BASE_URL}/locations/location`,
              data: data,
            })
              .then((res) => {
                if (res.data.msg === "success") {
                  axiosInstance({
                    method: "get",
                    url: `${BASE_URL}/locations/location`,
                    params: {
                      id: params.id,
                    },
                  })
                    .then((res) => {
                      setLocation(res.data)
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }}>
          update
        </button>
      </div>
    </div>
  )
}
