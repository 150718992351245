import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { BASE_URL } from "../../utils/Config"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const InsertKey = () => {
  const navigate = useNavigate()

  const [key, setKey] = useState("")

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-gray-800">
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <label
          htmlFor="key"
          className="block text-sm font-medium leading-6 text-gray-900">
          INPUT AGENCY API KEY OR LOCATION API KEY
        </label>
        <label
          htmlFor="key"
          className="block text-sm font-medium leading-6 text-gray-900">
          (Optional)
        </label>
        <div className="mt-2">
          <input
            type="text"
            id="key"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <button
            type="button"
            className={classNames(
              key === ""
                ? "cursor-default bg-indigo-400"
                : "cursor-pointer bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
              "rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            )}
            onClick={() => {
              const data = {
                user_id: Number(localStorage.getItem("id")),
                source_id: 1,
                type_id: 3,
                organization_id: 1,
                key: key,
              }
              axios
                .post(`${BASE_URL}/users/agencyKey`, data)
                .then((res) => {
                  if (res.data.success === "success") {
                    navigate("/sync-crm")
                  }
                })
                .catch((error) => {
                  console.error("Error:", error)
                })
            }}>
            Submit key
          </button>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => navigate("/sync-crm")}>
            Next step
          </button>
        </div>
      </div>
    </div>
  )
}
