import React from 'react';
import './style.css';

export const ProgressIndicator = ({ progress, onClose, onMinimize }) => {
  const radius = 18; // The radius of the circle used in the viewBox
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = ((100 - progress) / 100) * circumference;

  return (
    <div className="upload-modal">
      <div className="upload-modal-header">
        <button className="minimize-button" onClick={onMinimize}>-</button>
        <button className="close-button" onClick={onClose}>×</button>
      </div>
      <div className="upload-modal-content">
        <div className="file-status-text">
          {progress === 100 ? 'Upload Complete' : 'Uploading file...'}
        </div>
        <div className="progress-container">
          <svg className="progress-circle" viewBox="0 0 40 40">
            <circle
              className="progress-background"
              cx="20"
              cy="20"
              r={radius}
              fill="none"
              stroke="#e6e6e6"
              strokeWidth="4"
            />
            <circle
              className="progress-bar"
              cx="20"
              cy="20"
              r={radius}
              fill="none"
              stroke="#4CAF50"
              strokeWidth="4"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap="round"
              transform="rotate(-90 20 20)"
            />
          </svg>
          <div className="progress-text">{progress}%</div>
        </div>
      </div>
    </div>
  );
};
