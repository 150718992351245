import React, { useContext, createContext, useState } from "react"

const ThemeContext = createContext()

export function ThemeProvider({ children }) {
  const theme = useThemeProvider()
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  return useContext(ThemeContext)
}

function useThemeProvider() {
  const [currentTheme, setCurrentTheme] = useState(false)
  const [currentRecordingData, setCurrentRecordingData] = useState({})

  function saveTheme(setThemeParam) {
    setCurrentTheme(setThemeParam)
  }

  function saveRecordingData(recordingDataParam) {
    setCurrentRecordingData(recordingDataParam)
  }

  return {
    currentTheme,
    currentRecordingData,
    saveTheme,
    saveRecordingData,
  }
}
