import React, { useEffect, useState } from "react"
import {
  QuestionMarkCircleIcon,
  MicrophoneIcon,
  ClockIcon,
  XCircleIcon,
  BookmarkIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  CalculatorIcon,
} from "@heroicons/react/24/outline"
import Chart from "react-google-charts"
import axiosInstance from "../../utils/AxiosInstance"
import { BASE_URL } from "../../utils/Config"
import { useTheme } from "../../context/useTheme"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Dashboard = () => {
  const savedData = useTheme()
  const userId = localStorage.getItem("id")

  const [stats, setStats] = useState([])
  const [callStats, setCallStats] = useState([])
  const [totalRecordings, setTotalRecordings] = useState(0)
  const [totalSecs, setTotalSecs] = useState(0)
  const [totalMins, setTotalMins] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [totalObjections, setTotalObjections] = useState(0)
  const [totalPainPoints, setTotalPainPoints] = useState(0)
  const [totalPricingDiscuss, setTotalPricingDiscuss] = useState(0)
  // chart data
  const [pieChartData, setPieChartData] = useState([])
  const [pieChartoptions, setPieChartOptions] = useState({})
  const [lineChartData, setLineChartData] = useState([])
  const [lineChartoptions, setLineChartOptions] = useState({})
  // end chart data
  const [callResult, setCallResult] = useState({})
  const [recordingCounts, setRecordingCounts] = useState([])
  const [duration, setDuration] = useState(6)
  const [tabs, setTabs] = useState([
    { name: "1 Week", href: "#", current: true, value: 6 },
    { name: "1 Month", href: "#", current: false, value: 29 },
    { name: "1 Year", href: "#", current: false, value: 365 },
    { name: "All", href: "#", current: false, value: "all" },
  ])

  useEffect(() => {
    setPieChartData([
      ["Type", "Value"],
      ["Questions", 0],
      ["Objections", 0],
      ["Pain points", 0],
      ["Pricing discussed", 0],
    ])

    setLineChartData([
      ["Year", "Recording counts"],
      ["2004", 1000],
      ["2005", 1170],
      ["2006", 660],
      ["2007", 1030],
    ])

    setCallResult({
      appointment: 0,
      followUp: 0,
      notInterested: 0,
    })
  }, [])

  useEffect(() => {
    if (savedData.currentTheme) {
      setPieChartOptions({
        titleTextStyle: {
          fontSize: 18,
        },
        legend: {
          position: "top",
          maxLines: 3,
          textStyle: { fontSize: 14, color: "#d1d5db" },
        },
        slices: [
          { color: "rgb(28, 100, 242)" },
          { color: "rgb(144, 97, 249)" },
          { color: "rgb(22, 189, 202)" },
          { color: "rgb(231, 70, 148)" },
        ],
        backgroundColor: "#1f2937",
      })

      setLineChartOptions({
        curveType: "function",
        legend: {
          position: "top",
          textStyle: { fontSize: 14, color: "#d1d5db" },
        },
        backgroundColor: "#1f2937",
        hAxis: {
          textStyle: {
            color: "#d1d5db",
          },
        },
        vAxis: {
          textStyle: {
            color: "#d1d5db",
          },
        },
        baselineColor: "#ffffff",
      })
    } else {
      setPieChartOptions({
        titleTextStyle: {
          fontSize: 18,
        },
        legend: { position: "top", maxLines: 3, textStyle: { fontSize: 14 } },
        slices: [
          { color: "rgb(28, 100, 242)" },
          { color: "rgb(144, 97, 249)" },
          { color: "rgb(22, 189, 202)" },
          { color: "rgb(231, 70, 148)" },
        ],
      })

      setLineChartOptions({
        curveType: "function",
        legend: { position: "top", textStyle: { fontSize: 14 } },
      })
    }
  }, [savedData])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/dashboard/total-recordings`,
      params: {
        userId: userId,
      },
    })
      .then((res) => {
        setTotalRecordings(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${BASE_URL}/dashboard/total-times`,
      params: {
        userId: userId,
      },
    })
      .then((res) => {
        setTotalSecs(res.data.secs)
        setTotalMins(res.data.mins)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${BASE_URL}/dashboard/results`,
      params: {
        userId: userId,
      },
    })
      .then((res) => {
        setCallResult(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${BASE_URL}/dashboard/summary`,
      params: {
        userId: userId,
        duration: duration,
      },
    })
      .then((res) => {
        setTotalQuestions(res.data.totalQuestions)
        setTotalObjections(res.data.totalObjections)
        setTotalPainPoints(res.data.totalPainPoints)
        setTotalPricingDiscuss(res.data.totalPricingDiscuss)
        setRecordingCounts(res.data.recordingCounts)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [userId, duration])

  useEffect(() => {
    setStats([
      {
        id: 1,
        name: "Total Recordings",
        stat: totalRecordings,
        icon: MicrophoneIcon,
      },
      {
        id: 2,
        name: "Total Time",
        stat: `${totalMins}m : ${totalSecs}s`,
        icon: ClockIcon,
      },
      {
        id: 3,
        name: "Total Questions",
        stat: totalQuestions,
        icon: QuestionMarkCircleIcon,
      },
      {
        id: 4,
        name: "Total Objections",
        stat: totalObjections,
        icon: XCircleIcon,
      },
    ])

    setCallStats([
      {
        id: 1,
        name: "Appointment",
        stat: callResult.appointment,
        icon: BookmarkIcon,
      },
      {
        id: 2,
        name: "Follow-up",
        stat: callResult.followUp,
        icon: HandThumbUpIcon,
      },
      {
        id: 3,
        name: "Not Interested",
        stat: callResult.notInterested,
        icon: HandThumbDownIcon,
      },
      {
        id: 4,
        name: "Pricing Discussed",
        stat: totalPricingDiscuss,
        icon: CalculatorIcon,
      },
    ])

    setPieChartData([
      ["Type", "Value"],
      ["Questions", totalQuestions],
      ["Objections", totalObjections],
      ["Pain points", totalPainPoints],
      ["Pricing discussed", totalPricingDiscuss],
    ])
  }, [
    totalRecordings,
    totalSecs,
    totalMins,
    totalQuestions,
    totalObjections,
    totalPainPoints,
    totalPricingDiscuss,
    callResult,
  ])

  useEffect(() => {
    const chartValue = [["Year", "Recording counts"]]
    recordingCounts.forEach((item) => {
      const temp = [`${item.year}`, item.value.length]
      chartValue.push(temp)
    })
    setLineChartData(chartValue)
  }, [recordingCounts])
  return (
    <div>
      <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 px-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate xl:text-lg lg:text-sm font-medium text-gray-500 dark:text-gray-400">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <p className="xl:text-2xl lg:text-lg font-semibold text-gray-900 dark:text-gray-100">
                {item.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
      <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 px-4 pt-4">
        {callStats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate xl:text-lg lg:text-sm font-medium text-gray-500 dark:text-gray-400">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <p className="xl:text-2xl lg:text-lg font-semibold text-gray-900 dark:text-gray-100">
                {item.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
      <div className="pt-6 px-4">
        <div className="sm:hidden px-4">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[0].name}
            onChange={(e) => {
              e.preventDefault()
              tabs.forEach((tab) => {
                if (tab.name === e.target.value) {
                  setDuration(tab.value)
                }
              })
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 dark:divide-gray-600 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-800 py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={(e) => {
                  e.preventDefault()
                  tabs.forEach((tab) => {
                    tab.current = false
                  })
                  setDuration(tab.value)
                  setTabs((_prev) => {
                    const tmp = [..._prev]
                    tmp[tabIdx].current = true
                    return tmp
                  })
                }}
              >
                <span className="dark:text-gray-400 dark:hover:text-gray-300">
                  {tab.name}
                </span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
      <div className="pt-4 px-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="lg:col-span-1 rounded-lg shadow px-4 pt-10 2xl:pt-6 bg-white dark:bg-gray-800">
          <Chart
            chartType="PieChart"
            data={pieChartData}
            options={pieChartoptions}
            loader={<div className="dark:text-gray-200">Loading Chart</div>}
            width={"100%"}
            height={"500px"}
          />
        </div>
        <div className="lg:col-span-1 rounded-lg shadow p-4 bg-white dark:bg-gray-800">
          <Chart
            chartType="LineChart"
            data={lineChartData}
            options={lineChartoptions}
            loader={<div className="dark:text-gray-200">Loading Chart</div>}
            width={"100%"}
            height={"500px"}
          />
        </div>
      </div>
    </div>
  )
}
