import React, { useEffect, useState, Fragment } from "react"
import { useParams, Link } from "react-router-dom"
import {
  UserIcon,
  MicrophoneIcon,
  EyeIcon,
  ChartPieIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/20/solid"
import Chart from "react-google-charts"
import { RingLoader } from "react-spinners"
import { useTheme } from "../../context/useTheme"
import axiosInstance from "../../utils/AxiosInstance"
import { BASE_URL } from "../../utils/Config"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const override = {
  borderColor: "#2317e6",
  zIndex: 31,
}

export const ShareAnalysis = () => {
  const params = useParams()

  const [tabs, setTabs] = useState([])
  const [currentTab, setCurrentTab] = useState("Highlights")
  const [callAnalysisData, setCallAnalysisData] = useState({})
  const [chartData, setChartData] = useState([])
  const [options, setOptions] = useState({})
  const savedData = useTheme()
  const [qaData, setQAData] = useState([])
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState("Transcription")
  const [coachingContent, setCoachingContent] = useState("")

  useEffect(() => {
    setCategories([
      { name: "Transcription", href: "#", icon: MicrophoneIcon, current: true },
      {
        name: "Dialogue",
        href: "#",
        icon: ChatBubbleLeftEllipsisIcon,
        current: false,
      },
    ])

    setTabs([
      { name: "Highlights", current: true },
      // { name: "Phrases", current: false },
      { name: "Coaching", current: false },
    ])

    setChartData([
      ["Type", "Value"],
      ["Questions", 0],
      ["Objections", 0],
      ["Pain points", 0],
      ["Pricing discussed", 0],
    ])
  }, [])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/analysis/shareable`,
      params: {
        link: params.link,
      },
    })
      .then((res) => {
        setCallAnalysisData(res.data)
        res.data.coaching && setCoachingContent(res.data.coaching)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [params.link])

  useEffect(() => {
    if (callAnalysisData.id !== undefined) {
      if (savedData.currentTheme) {
        setOptions({
          titleTextStyle: {
            fontSize: 18,
          },
          legend: {
            position: "top",
            maxLines: 3,
            textStyle: { fontSize: 14, color: "#d1d5db" },
          },
          slices: [
            { color: "rgb(28, 100, 242)" },
            { color: "rgb(144, 97, 249)" },
            { color: "rgb(22, 189, 202)" },
            { color: "rgb(231, 70, 148)" },
          ],
          backgroundColor: "#1f2937",
        })
      } else {
        setOptions({
          titleTextStyle: {
            fontSize: 18,
          },
          legend: { position: "top", maxLines: 3, textStyle: { fontSize: 14 } },
          slices: [
            { color: "rgb(28, 100, 242)" },
            { color: "rgb(144, 97, 249)" },
            { color: "rgb(22, 189, 202)" },
            { color: "rgb(231, 70, 148)" },
          ],
        })
      }
      setChartData([
        ["Type", "Value"],
        ["Questions", callAnalysisData.analysis.questions.length],
        ["Objections", callAnalysisData.analysis.objections.length],
        ["Pain points", callAnalysisData.analysis.pain_points.length],
        [
          "Pricing discussed",
          callAnalysisData.analysis.pricing_discussed.length,
        ],
      ])
      const listItems = []
      for (let i = 0; i < callAnalysisData.analysis.questions.length; i++) {
        listItems.push(
          <div key={i}>
            <p className="text-sm dark:text-gray-200 leading-7 font-bold">
              Q{i + 1}: {callAnalysisData.analysis.questions[i]}
            </p>
            <p className="text-sm dark:text-gray-200 leading-7">
              A{i + 1}: {callAnalysisData.analysis.answers[i]}
            </p>
          </div>
        )
      }
      setQAData(listItems)
    }
  }, [callAnalysisData, savedData])

  return (
    <div className="p-8 bg-gray-100">
      {loading ? (
        <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
          <RingLoader
            color="#2317e6"
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div>
          <div className="mx-4 mb-4 flex justify-between items-center">
            <div className="flex items-center">
              <ChartBarSquareIcon className="w-6 h-6 mr-2 dark:text-gray-100" />
              <h3 className="text-2xl font-bold leading-6 text-gray-900 dark:text-gray-100">
                Call analysis
              </h3>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-4 gap-y-10 mx-4 mb-4">
            <div className="lg:col-span-2 flex flex-col max-h-[1000px]">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-10 mb-4">
                <div className="lg:col-span-1 px-4 py-10 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded shadow">
                  <div className="flex items-center pb-4">
                    <UserIcon className="w-6 h-6 dark:text-gray-100" />
                    <span className="font-bold text-xl pl-2 dark:text-gray-100">
                      Contact
                    </span>
                  </div>
                  <div>
                    <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                      Contact name:
                    </label>
                    <p className="text-sm dark:text-gray-200">
                      {callAnalysisData.crm_contactName
                        ? callAnalysisData.crm_contactName
                        : ""}
                    </p>
                  </div>
                  <div>
                    <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                      Phone number:
                    </label>
                    <p className="text-sm dark:text-gray-200">
                      {callAnalysisData.phone_number
                        ? callAnalysisData.phone_number
                        : ""}
                    </p>
                  </div>
                  <div>
                    <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                      Email:
                    </label>
                    <p className="text-sm dark:text-gray-200">
                      {callAnalysisData.crm_email
                        ? callAnalysisData.crm_email
                        : ""}
                    </p>
                  </div>
                  <div>
                    <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                      Account name:
                    </label>
                    <p className="text-sm dark:text-gray-200">
                      {callAnalysisData.location
                        ? callAnalysisData.location.name
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="lg:col-span-2 px-4 py-10 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded shadow">
                  <div className="flex items-center pb-4">
                    <ChartPieIcon className="w-6 h-6 dark:text-gray-100" />
                    <span className="font-bold text-xl pl-2 dark:text-gray-100">
                      Call Analysis
                    </span>
                  </div>
                  <Chart
                    chartType="PieChart"
                    data={chartData}
                    options={options}
                    loader={
                      <div className="dark:text-gray-200">Loading Chart</div>
                    }
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
              <div className=" grid grid-cols-1 px-4 py-10 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded shadow overflow-y-auto">
                {categories.length > 0 && (
                  <div className="pb-4">
                    <div className="sm:hidden">
                      <label htmlFor="Categories" className="sr-only">
                        Select a category
                      </label>
                      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                      <select
                        id="Categories"
                        name="Categories"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        defaultValue={
                          categories.find((category) => category.current).name
                        }
                        onChange={(e) => setCurrentCategory(e.target.value)}
                      >
                        {categories.map((category) => (
                          <option key={category.name}>{category.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-300 dark:border-gray-600">
                        <nav
                          className="-mb-px flex space-x-8 items-center"
                          aria-label="Categories"
                        >
                          {categories.map((category) => (
                            <a
                              key={category.name}
                              href={category.href}
                              className={classNames(
                                category.name === currentCategory
                                  ? "border-indigo-500 text-indigo-500"
                                  : "border-transparent text-gray-900 dark:text-gray-100",
                                "group inline-flex items-center border-b-2 pb-4 px-1 text-sm font-medium"
                              )}
                              onClick={(e) => {
                                e.preventDefault()
                                setCurrentCategory(category.name)
                              }}
                            >
                              <category.icon
                                className={classNames(
                                  category.name === currentCategory
                                    ? "text-indigo-500 dark:text-indigo-500"
                                    : "dark:text-gray-100 text-gray-900",
                                  "w-6 h-6"
                                )}
                                aria-hidden="true"
                              />
                              <span className="mx-2 font-bold text-xl">
                                {category.name}
                              </span>
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                )}
                {currentCategory === "Transcription" ? (
                  <p className="mx-auto text-sm dark:text-gray-200">
                    {callAnalysisData.transcription}
                  </p>
                ) : (
                  <p className="mx-auto text-sm dark:text-gray-200"></p>
                )}
              </div>
            </div>
            <div className=" lg:col-span-1 px-4 py-10 sm:px-6 lg:px-8  h-[1000px] overflow-y-auto overflow-x-hidden bg-white dark:bg-gray-800 rounded shadow">
              <div className="flex items-center pb-4">
                <EyeIcon className="w-6 h-6 dark:text-gray-100" />
                <span className="font-bold text-xl pl-2 dark:text-gray-100">
                  Call Insights
                </span>
              </div>
              <div>
                <div className="sm:hidden pb-4">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={
                      tabs.length > 0 && tabs.find((tab) => tab.current).name
                    }
                    onChange={(e) => {
                      setCurrentTab(e.target.value)
                    }}
                  >
                    {tabs.length > 0 &&
                      tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                  </select>
                </div>
                <div className="hidden sm:block w-full pb-4">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-4" aria-label="Tabs">
                      {tabs.length > 0 &&
                        tabs.map((tab) => (
                          <Link
                            key={tab.name}
                            className={classNames(
                              tab.current
                                ? "border-indigo-500 dark:border-gray-100 text-indigo-600 dark:text-gray-100"
                                : "border-transparent text-gray-500 hover:border-gray-300 dark:hover:text-gray-100 hover:text-gray-700",
                              "whitespace-nowrap border-b-2 pb-4 px-1 text-sm font-medium"
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              setTabs((_prev) => {
                                const temp = [..._prev]
                                for (let i = 0; i < temp.length; i++) {
                                  if (temp[i].name === tab.name) {
                                    temp[i].current = true
                                  } else {
                                    temp[i].current = false
                                  }
                                }
                                return temp
                              })
                              setCurrentTab(tab.name)
                            }}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            {tab.name}
                          </Link>
                        ))}
                    </nav>
                  </div>
                </div>
                {currentTab === "Highlights" && (
                  <div>
                    <p className="font-semibold text-lg dark:text-gray-100">
                      Questions & Answers:
                    </p>
                    {qaData}
                    <p className="font-semibold text-lg dark:text-gray-100">
                      Objections:
                    </p>
                    {callAnalysisData.analysis &&
                      callAnalysisData.analysis.objections.map(
                        (objection, index) => (
                          <p
                            key={index}
                            className="text-sm dark:text-gray-200 leading-7"
                          >
                            {objection}.
                          </p>
                        )
                      )}
                    <p className="font-semibold text-lg dark:text-gray-100">
                      Pain points:
                    </p>
                    {callAnalysisData.analysis &&
                      callAnalysisData.analysis.pain_points.map(
                        (pain_point, index) => (
                          <p
                            key={index}
                            className="text-sm dark:text-gray-200 leading-7"
                          >
                            {pain_point}.
                          </p>
                        )
                      )}
                    <p className="font-semibold text-lg dark:text-gray-100">
                      Pricing discussed:
                    </p>
                    {callAnalysisData.analysis &&
                      callAnalysisData.analysis.pricing_discussed.map(
                        (pricing, index) => (
                          <p
                            key={index}
                            className="text-sm dark:text-gray-200 leading-7"
                          >
                            {pricing}.
                          </p>
                        )
                      )}
                    <p className="font-semibold text-lg dark:text-gray-100">
                      Call results:
                    </p>
                    {callAnalysisData.analysis && (
                      <p className="text-sm dark:text-gray-200 leading-7">
                        {callAnalysisData.analysis.call_results}.
                      </p>
                    )}
                    <p className="font-semibold text-lg dark:text-gray-100">
                      Call Summary:
                    </p>
                    {callAnalysisData.analysis && (
                      <p className="text-sm dark:text-gray-200 leading-7">
                        {callAnalysisData.analysis.call_summary}.
                      </p>
                    )}
                  </div>
                )}
                {currentTab === "Phrases" && (
                  <p className="text-gray-900 dark:text-white font-bold mb-8 animate-pulse">
                    Coming Soon
                  </p>
                )}
                {currentTab === "Coaching" && (
                  <Fragment>
                    {coachingContent &&
                      coachingContent.split("\n").map((item, index) => {
                        const matches = item.match(/^[a-zA-Z -/()]*:/)
                        if (matches && matches.length > 0) {
                          return (
                            <p
                              key={`${index}-${new Date().getTime()}`}
                              className="pt-4"
                            >
                              <b>{matches[0]}</b>
                              {item.slice(matches[0].length)}
                            </p>
                          )
                        } else
                          return (
                            <p
                              key={`${index}-${new Date().getTime()}`}
                              className="pt-4"
                            >
                              {item}
                            </p>
                          )
                      })}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
