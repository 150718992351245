import { useEffect, useState, Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/24/solid"
import { BASE_URL } from "../../../utils/Config"
import axiosInstance from "../../../utils/AxiosInstance"
import { useAuth } from "../../../context/useAuth"

export const Profile = () => {
  const [userData, setUserData] = useState({})
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isWrongPassword, setIsWrongPassword] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const id = Number(localStorage.getItem("id"))

  const auth = useAuth()

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/users/userId/${id}`,
    })
      .then((res) => {
        setUserData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="divide-y divide-white/5">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8 bg-white dark:bg-gray-800 mx-4 mb-4 rounded shadow">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
              Personal Information
            </h2>
          </div>

          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="first-name"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    defaultValue={userData.firstname ? userData.firstname : ""}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="last-name"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    defaultValue={userData.lastname ? userData.lastname : ""}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    id="email"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    disabled
                    defaultValue={userData.email}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  Username
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="username"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    defaultValue={userData.username ? userData.username : ""}
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="userId"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  User ID
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="userId"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    disabled
                    defaultValue={userData.id}
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                type="button"
                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={() => {
                  const data = {
                    first_name: firstName,
                    last_name: lastName,
                    email: userData.email,
                    username: username,
                  }
                  axiosInstance({
                    method: "put",
                    url: `${BASE_URL}/users`,
                    data: data,
                  })
                    .then(() => {
                      setOpenSuccessModal(true)
                      auth.saveUser({
                        ...auth.account,
                      })
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8 bg-white dark:bg-gray-800 mx-4 mb-4 rounded shadow">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
              Change password
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300">
              Update your password associated with your account.
            </p>
          </div>

          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="new-password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  New password
                </label>
                <div className="mt-2">
                  <input
                    id="new-password"
                    type="password"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  Confirm password
                </label>
                <div className="mt-2">
                  <input
                    id="confirm-password"
                    type="password"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      if (e.target.value !== password) {
                        setIsWrongPassword(true)
                      } else setIsWrongPassword(false)
                    }}
                  />
                  {isWrongPassword && (
                    <p className="text-red-700 dark:text-red-500">
                      Password and Confirm password isn't matched
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                type="button"
                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={() => {
                  const data = {
                    email: userData.email,
                    password: password,
                  }
                  axiosInstance({
                    method: "put",
                    url: `${BASE_URL}/users/change-password`,
                    data: data,
                  })
                    .then(() => {
                      setOpenSuccessModal(true)
                      auth.saveUser({
                        ...auth.account,
                      })
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* profile update success modal */}
      <Transition.Root show={openSuccessModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenSuccessModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 dark:bg-green-300">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Updated successful
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpenSuccessModal(false)}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end profile update success modal */}
    </div>
  )
}
