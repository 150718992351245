import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { logos } from "../../assets"
import { useTheme } from "../../context/useTheme"
import axiosInstance from "../../utils/AxiosInstance"
import { BASE_URL } from "../../utils/Config"

export const Signup = () => {
  const savedData = useTheme()
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userName, setUserName] = useState("")
  const [organization, setOrganization] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isWrong, setIsWrong] = useState(false)
  const [isExistUser, setIsExistUser] = useState(false)

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-gray-800">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {savedData.currentTheme ? (
          <img
            className="mx-auto h-10 w-auto"
            src={logos.Logo}
            alt="Your Company"
          />
        ) : (
          <img
            className="mx-auto h-10 w-auto"
            src={logos.DarkLogo}
            alt="Your Company"
          />
        )}
        <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
          Signup your account
        </h2>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              First name
            </label>
            <div className="mt-2">
              <input
                id="first_name"
                type="text"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              Last name
            </label>
            <div className="mt-2">
              <input
                id="last_name"
                type="text"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                type="text"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                type="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            {isExistUser && (
              <p className="text-red-700">
                This username or email is already taken.
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="organization"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
              Organization
            </label>
            <div className="mt-2">
              <input
                id="organization"
                type="text"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setOrganization(e.target.value)
                }}
              />
            </div>
            {isExistUser && (
              <p className="text-red-700">
                This username or email is already taken.
              </p>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                Confirm password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="confirmPassword"
                type="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  if (e.target.value !== password) {
                    setIsWrong(true)
                  } else {
                    setIsWrong(false)
                    setConfirmPassword(e.target.value)
                  }
                }}
              />
              {isWrong && (
                <p className="text-red-700 dark:text-red-500">
                  Password and Confirm password isn't matched
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              type="button"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                if (!isWrong) {
                  const data = {
                    firstName: firstName,
                    lastName: lastName,
                    userName: userName,
                    email: email,
                    organization: organization,
                    password: password,
                    confirmPassword: confirmPassword,
                  }
                  axiosInstance({
                    method: "post",
                    url: `${BASE_URL}/users`,
                    data: data,
                  })
                    .then((res) => {
                      localStorage.setItem("id", res.data.id)
                      navigate("/sync-crm")
                    })
                    .catch((err) => {
                      console.log(err)
                      if (
                        err.response.data.msg ===
                        "This username or email is already taken."
                      )
                        setIsExistUser(true)
                    })
                }
              }}>
              Sign up
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-300">
          Already have an account?{" "}
          <Link
            to="/signin"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  )
}