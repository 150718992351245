import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { logos } from "../../assets"
import { useTheme } from "../../context/useTheme"
import axiosInstance from "../../utils/AxiosInstance"
import { BASE_URL } from "../../utils/Config"
import { useAuth } from "../../context/useAuth"

export const Signin = () => {
  const savedData = useTheme()
  const navigate = useNavigate()
  const auth = useAuth()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isWrongPassword, setIsWrongPassword] = useState(false)
  const [isWrongUser, setIsWrongUser] = useState(false)

  const Sign = async (e) => {
    e.preventDefault()
    const data = {
      email: email,
      password: password,
    }
    axiosInstance({
      method: "post",
      url: `${BASE_URL}/users/login`,
      data: data,
    })
      .then((res) => {
        if (res.data.accessToken) {
          auth.saveAccount(res.data)
          navigate("/main/dashboard")
        } else {
          navigate("/signin")
        }
      })
      .catch((err) => {
        if (err.response.data.msg === "Wrong Password") {
          setIsWrongPassword(true)
        }
        if (err.response.data.msg === "Wrong User") {
          setIsWrongUser(true)
        }
      })
  }

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-gray-800">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {savedData.currentTheme ? (
            <img
              className="mx-auto h-10 w-auto"
              src={logos.Logo}
              alt="Your Company"
            />
          ) : (
            <img
              className="mx-auto h-10 w-auto"
              src={logos.DarkLogo}
              alt="Your Company"
            />
          )}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={Sign}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  type="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {isWrongUser && (
                <p className="text-red-700">This user doesn't exist</p>
              )}
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  type="password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {isWrongPassword && (
                <p className="text-red-700">You entered wrong password</p>
              )}
            </div>

            <div>
              <button className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-300">
            Not a member?{" "}
            <Link
              to="/signup"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}