import { useState, useEffect, Fragment } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  UserIcon,
  MicrophoneIcon,
  EyeIcon,
  ChartPieIcon,
  ChartBarSquareIcon,
  ArrowLeftIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ShareIcon,
} from "@heroicons/react/20/solid"
import { Listbox, Transition, Dialog } from "@headlessui/react"
import Chart from "react-google-charts"
import { RingLoader } from "react-spinners"
import { useTheme } from "../../context/useTheme"
import { BASE_URL } from "../../utils/Config"
import axiosInstance from "../../utils/AxiosInstance"
import { Tooltip } from "../../components/Tooltip"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const override = {
  borderColor: "#2317e6",
  zIndex: 31,
}

export const CallAnalysis = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [tabs, setTabs] = useState([])
  const [currentTab, setCurrentTab] = useState("Highlights")
  const [callAnalysisData, setCallAnalysisData] = useState({})
  const [chartData, setChartData] = useState([])
  const [options, setOptions] = useState({})
  const savedData = useTheme()
  const [qaData, setQAData] = useState([])
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState("Transcription")
  // for coaching section
  const [coachs, setCoachs] = useState([])
  const [methods, setMethods] = useState([])
  const [selectedCoachName, setSelectedCoachName] = useState({})
  const [selectedMethod, setSelectedMethod] = useState({})
  const [coachingContent, setCoachingContent] = useState("")
  // end coaching section
  const [copySuccess, setCopySuccess] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  useEffect(() => {
    setCategories([
      { name: "Transcription", href: "#", icon: MicrophoneIcon, current: true },
      {
        name: "Dialogue",
        href: "#",
        icon: ChatBubbleLeftEllipsisIcon,
        current: false,
      },
    ])

    setCoachs([{ id: 1, name: "Jeremy Miner" }])
    setMethods([{ id: 1, name: "NEPQ" }])

    setTabs([
      { name: "Highlights", current: true },
      // { name: "Phrases", current: false },
      { name: "Coaching", current: false },
    ])

    setChartData([
      ["Type", "Value"],
      ["Questions", 0],
      ["Objections", 0],
      ["Pain points", 0],
      ["Pricing discussed", 0],
    ])
  }, [])

  useEffect(() => {
    setSelectedCoachName(coachs[0])
  }, [coachs])

  useEffect(() => {
    setSelectedMethod(methods[0])
  }, [methods])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/analysis/analysis`,
      params: {
        id: params.id,
      },
    })
      .then((res) => {
        setCallAnalysisData(res.data)
        res.data.coaching && setCoachingContent(res.data.coaching)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [params.id])

  useEffect(() => {
    if (callAnalysisData.id !== undefined) {
      if (savedData.currentTheme) {
        setOptions({
          titleTextStyle: {
            fontSize: 18,
          },
          legend: {
            position: "top",
            maxLines: 3,
            textStyle: { fontSize: 14, color: "#d1d5db" },
          },
          slices: [
            { color: "rgb(28, 100, 242)" },
            { color: "rgb(144, 97, 249)" },
            { color: "rgb(22, 189, 202)" },
            { color: "rgb(231, 70, 148)" },
          ],
          backgroundColor: "#1f2937",
        })
      } else {
        setOptions({
          titleTextStyle: {
            fontSize: 18,
          },
          legend: { position: "top", maxLines: 3, textStyle: { fontSize: 14 } },
          slices: [
            { color: "rgb(28, 100, 242)" },
            { color: "rgb(144, 97, 249)" },
            { color: "rgb(22, 189, 202)" },
            { color: "rgb(231, 70, 148)" },
          ],
        })
      }
      setChartData([
        ["Type", "Value"],
        ["Questions", callAnalysisData.analysis.questions.length],
        ["Objections", callAnalysisData.analysis.objections.length],
        ["Pain points", callAnalysisData.analysis.pain_points.length],
        [
          "Pricing discussed",
          callAnalysisData.analysis.pricing_discussed.length,
        ],
      ])
      const listItems = []
      for (let i = 0; i < callAnalysisData.analysis.questions.length; i++) {
        listItems.push(
          <div key={i}>
            <p className="text-sm dark:text-gray-200 leading-7 font-bold">
              Q{i + 1}: {callAnalysisData.analysis.questions[i]}
            </p>
            <p className="text-sm dark:text-gray-200 leading-7">
              A{i + 1}: {callAnalysisData.analysis.answers[i]}
            </p>
          </div>
        )
      }
      setQAData(listItems)
    }
  }, [callAnalysisData, savedData])

  useEffect(() => {
    if (copySuccess) {
      setShareModalOpen(true);
      setTimeout(() => {
        setCopySuccess(false)
      }, 2000)
    }
  }, [copySuccess])

  return (
    <>
      <div>
        {loading ? (
          <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
            <RingLoader
              color="#2317e6"
              loading={loading}
              cssOverride={override}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div>
            <div className="mx-4 mb-4 flex justify-between items-center">
              <div className="flex items-center">
                <ChartBarSquareIcon className="w-6 h-6 mr-2 dark:text-gray-100" />
                <h3 className="text-2xl font-bold leading-6 text-gray-900 dark:text-gray-100">
                  Call analysis
                </h3>
              </div>
              <div className="flex items-center">
                {copySuccess && <p className="mr-2">Copied</p>}
                <Tooltip
                  message="Click here to share this info with others"
                  className="w-max -bottom-9 right-0"
                >
                  <button
                    type="button"
                    className="flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-2"
                    onClick={() => {
                      const id = params.id
                      setLoading(true)
                      axiosInstance({
                        method: "post",
                        url: `${BASE_URL}/analysis/link-generator`,
                        data: { id: id },
                      })
                        .then(async (res) => {
                          try {
                            await navigator.clipboard.writeText(res.data)
                            setCopySuccess(true)
                            setLoading(false)
                          } catch (err) {
                            alert(err)
                          }
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                    }}
                  >
                    <Fragment>
                      <ShareIcon className="w-6 h-6 mr-2" />
                      Share
                    </Fragment>
                  </button>
                </Tooltip>
                <button
                  type="button"
                  className="flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    navigate("/main/recordings")
                  }}
                >
                  <ArrowLeftIcon className="w-6 h-6 mr-2" />
                  Back
                </button>
              </div>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-4 gap-y-10 mx-4 mb-4">
              <div className="lg:col-span-2 flex flex-col max-h-[1000px]">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-10 mb-4">
                  <div className="lg:col-span-1 px-4 py-10 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded shadow">
                    <div className="flex items-center pb-4">
                      <UserIcon className="w-6 h-6 dark:text-gray-100" />
                      <span className="font-bold text-xl pl-2 dark:text-gray-100">
                        Contact
                      </span>
                    </div>
                    <div>
                      <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                        Contact name:
                      </label>
                      <p className="text-sm dark:text-gray-200">
                        {callAnalysisData.crm_contactName
                          ? callAnalysisData.crm_contactName
                          : ""}
                      </p>
                    </div>
                    <div>
                      <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                        Phone number:
                      </label>
                      <p className="text-sm dark:text-gray-200">
                        {callAnalysisData.phone_number
                          ? callAnalysisData.phone_number
                          : ""}
                      </p>
                    </div>
                    <div>
                      <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                        Email:
                      </label>
                      <p className="text-sm dark:text-gray-200">
                        {callAnalysisData.crm_email
                          ? callAnalysisData.crm_email
                          : ""}
                      </p>
                    </div>
                    <div>
                      <label className="block font-medium leading-6 text-gray-900 dark:text-gray-100">
                        Account name:
                      </label>
                      <p className="text-sm dark:text-gray-200">
                        {callAnalysisData.location
                          ? callAnalysisData.location.name
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="lg:col-span-2 px-4 py-10 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded shadow">
                    <div className="flex items-center pb-4">
                      <ChartPieIcon className="w-6 h-6 dark:text-gray-100" />
                      <span className="font-bold text-xl pl-2 dark:text-gray-100">
                        Call Analysis
                      </span>
                    </div>
                    <Chart
                      chartType="PieChart"
                      data={chartData}
                      options={options}
                      loader={
                        <div className="dark:text-gray-200">Loading Chart</div>
                      }
                      width={"100%"}
                      height={"400px"}
                    />
                  </div>
                </div>
                <div className=" grid grid-cols-1 px-4 py-10 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded shadow overflow-y-auto">
                  {categories.length > 0 && (
                    <div className="pb-4">
                      <div className="sm:hidden">
                        <label htmlFor="Categories" className="sr-only">
                          Select a category
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                          id="Categories"
                          name="Categories"
                          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          defaultValue={
                            categories.find((category) => category.current).name
                          }
                          onChange={(e) => setCurrentCategory(e.target.value)}
                        >
                          {categories.map((category) => (
                            <option key={category.name}>{category.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden sm:block">
                        <div className="border-b border-gray-300 dark:border-gray-600">
                          <nav
                            className="-mb-px flex space-x-8 items-center"
                            aria-label="Categories"
                          >
                            {categories.map((category) => (
                              <a
                                key={category.name}
                                href={category.href}
                                className={classNames(
                                  category.name === currentCategory
                                    ? "border-indigo-500 text-indigo-500"
                                    : "border-transparent text-gray-900 dark:text-gray-100",
                                  "group inline-flex items-center border-b-2 pb-4 px-1 text-sm font-medium"
                                )}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCurrentCategory(category.name)
                                }}
                              >
                                <category.icon
                                  className={classNames(
                                    category.name === currentCategory
                                      ? "text-indigo-500 dark:text-indigo-500"
                                      : "dark:text-gray-100 text-gray-900",
                                    "w-6 h-6"
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="mx-2 font-bold text-xl">
                                  {category.name}
                                </span>
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentCategory === "Transcription" ? (
                    <p className="mx-auto text-sm dark:text-gray-200">
                      {callAnalysisData.transcription}
                    </p>
                  ) : (
                    <p className="mx-auto text-sm dark:text-gray-200"></p>
                  )}
                </div>
              </div>
              <div className=" lg:col-span-1 px-4 py-10 sm:px-6 lg:px-8  h-[1000px] overflow-y-auto overflow-x-hidden bg-white dark:bg-gray-800 rounded shadow">
                <div className="flex items-center pb-4">
                  <EyeIcon className="w-6 h-6 dark:text-gray-100" />
                  <span className="font-bold text-xl pl-2 dark:text-gray-100">
                    Call Insights
                  </span>
                </div>
                <div>
                  <div className="sm:hidden pb-4">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={
                        tabs.length > 0 && tabs.find((tab) => tab.current).name
                      }
                      onChange={(e) => {
                        setCurrentTab(e.target.value)
                      }}
                    >
                      {tabs.length > 0 &&
                        tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="hidden sm:block w-full pb-4">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-4" aria-label="Tabs">
                        {tabs.length > 0 &&
                          tabs.map((tab) => (
                            <Link
                              key={tab.name}
                              className={classNames(
                                tab.current
                                  ? "border-indigo-500 dark:border-gray-100 text-indigo-600 dark:text-gray-100"
                                  : "border-transparent text-gray-500 hover:border-gray-300 dark:hover:text-gray-100 hover:text-gray-700",
                                "whitespace-nowrap border-b-2 pb-4 px-1 text-sm font-medium"
                              )}
                              onClick={(e) => {
                                e.preventDefault()
                                setTabs((_prev) => {
                                  const temp = [..._prev]
                                  for (let i = 0; i < temp.length; i++) {
                                    if (temp[i].name === tab.name) {
                                      temp[i].current = true
                                    } else {
                                      temp[i].current = false
                                    }
                                  }
                                  return temp
                                })
                                setCurrentTab(tab.name)
                              }}
                              aria-current={tab.current ? "page" : undefined}
                            >
                              {tab.name}
                            </Link>
                          ))}
                      </nav>
                    </div>
                  </div>
                  {currentTab === "Highlights" && (
                    <div>
                      <p className="font-semibold text-lg dark:text-gray-100">
                        Call results:
                      </p>
                      {callAnalysisData.analysis && (
                        <p className="text-sm dark:text-gray-200 leading-7">
                          {callAnalysisData.analysis.call_results}
                        </p>
                      )}
                      <p className="font-semibold text-lg dark:text-gray-100">
                        Call Summary:
                      </p>
                      {callAnalysisData.analysis && (
                        <p className="text-sm dark:text-gray-200 leading-7">
                          {callAnalysisData.analysis.call_summary}
                        </p>
                      )}
                      <p className="font-semibold text-lg dark:text-gray-100">
                        Questions & Answers:
                      </p>
                      {qaData}
                      <p className="font-semibold text-lg dark:text-gray-100">
                        Objections:
                      </p>
                      {callAnalysisData.analysis &&
                        callAnalysisData.analysis.objections.map(
                          (objection, index) => (
                            <p
                              key={index}
                              className="text-sm dark:text-gray-200 leading-7"
                            >
                              {objection}.
                            </p>
                          )
                        )}
                      <p className="font-semibold text-lg dark:text-gray-100">
                        Pain points:
                      </p>
                      {callAnalysisData.analysis &&
                        callAnalysisData.analysis.pain_points.map(
                          (pain_point, index) => (
                            <p
                              key={index}
                              className="text-sm dark:text-gray-200 leading-7"
                            >
                              {pain_point}.
                            </p>
                          )
                        )}
                      <p className="font-semibold text-lg dark:text-gray-100">
                        Pricing discussed:
                      </p>
                      {callAnalysisData.analysis &&
                        callAnalysisData.analysis.pricing_discussed.map(
                          (pricing, index) => (
                            <p
                              key={index}
                              className="text-sm dark:text-gray-200 leading-7"
                            >
                              {pricing}.
                            </p>
                          )
                        )}
                      
                    </div>
                  )}
                  {currentTab === "Phrases" && (
                    <p className="text-gray-900 dark:text-white font-bold mb-8 animate-pulse">
                      Coming Soon
                    </p>
                  )}
                  {currentTab === "Coaching" && (
                    <Fragment>
                      <Listbox
                        value={selectedCoachName}
                        onChange={setSelectedCoachName}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                              Coach name
                            </Listbox.Label>
                            <div className="relative mt-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white dark:bg-gray-900 py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                  {selectedCoachName.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {coachs.map((coach) => (
                                    <Listbox.Option
                                      key={coach.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "bg-indigo-600 text-white"
                                            : "text-gray-900 dark:text-gray-100",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={coach}
                                    >
                                      {({ selectedCoachName, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selectedCoachName
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {coach.name}
                                          </span>

                                          {selectedCoachName ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-indigo-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>

                      <Listbox
                        value={selectedMethod}
                        onChange={setSelectedMethod}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100 pt-4">
                              Method
                            </Listbox.Label>
                            <div className="relative mt-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white dark:bg-gray-900 py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                  {selectedMethod.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {methods.map((method) => (
                                    <Listbox.Option
                                      key={method.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "bg-indigo-600 text-white"
                                            : "text-gray-900 dark:text-gray-100",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={method}
                                    >
                                      {({ selectedMethod, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selectedMethod
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {method.name}
                                          </span>

                                          {selectedMethod ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-indigo-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                      <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-4"
                        onClick={() => {
                          const data = {
                            id: params.id,
                            transcription: callAnalysisData.transcription,
                            coach_name: selectedCoachName.name,
                            method: selectedMethod.name,
                          }
                          setLoading(true)
                          axiosInstance({
                            method: "post",
                            url: `${BASE_URL}/analysis/coaching`,
                            data: data,
                          })
                            .then((res) => {
                              setCoachingContent(res.data.coaching)
                            })
                            .catch((err) => {
                              console.log(err)
                            })
                            .finally(() => {
                              setLoading(false)
                            })
                        }}
                      >
                        Get Feedback
                      </button>
                      {coachingContent &&
                        coachingContent.split("\n").map((item, index) => {
                          const matches = item.match(/^[a-zA-Z -/()]*:/)
                          if (matches && matches.length > 0) {
                            return (
                              <p
                                key={`${index}-${new Date().getTime()}`}
                                className="pt-4"
                              >
                                <b>{matches[0]}</b>
                                {item.slice(matches[0].length)}
                              </p>
                            )
                          } else
                            return (
                              <p
                                key={`${index}-${new Date().getTime()}`}
                                className="pt-4"
                              >
                                {item}
                              </p>
                            )
                        })}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <ShareModal shareModalOpen={shareModalOpen} setShareModalOpen={setShareModalOpen} />
    </>
  )
}


function ShareModal({shareModalOpen, setShareModalOpen}) {
  return <>
    <Transition.Root show={shareModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShareModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="text-center flex flex-col items-center">
                  <div className="p-2 rounded-full flex items-center justify-center bg-green-500/20 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      style={{width: "2rem", height: "2rem"}}                      
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </div>
                  <div className="mt-4 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                    >
                      Link copied successfully
                    </Dialog.Title>
                  </div>

                  <p className="text-center dark:text-gray-200 mt-6">
                    The link has been copied to your clipboard and can be used to access this analysis at any time, and by anyone who has it.
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </>
}