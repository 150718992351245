import React from "react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Tooltip = ({ message, children, className }) => {
  return (
    <div className="group relative flex">
      {children}
      <span
        className={classNames(
          `absolute scale-0 transition-all rounded bg-gray-800 dark:bg-gray-400 p-2 text-xs text-white dark:text-gray-900 group-hover:scale-100 ${className}`
        )}>
        {message}
      </span>
    </div>
  )
}
