import { Fragment, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import axios from "axios"
import { BASE_URL } from "../../utils/Config"

export const SyncCRM = () => {
  const navigate = useNavigate()
  const cancelButtonRef = useRef(null)
  const userId = localStorage.getItem("id")

  const [open, setOpen] = useState(false)
  const [userType, setUserType] = useState("Location")

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-gray-800">
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-xl">
        <p className="text-xl dark:text-gray-100">
          Sync CRM with the button below and sign into HighLevel and you will be
          prompted to select an Account. If you want to select all accounts,
          sync your Agency account and then allow access to all sub-accounts. If
          you want to select a single account, please select just one account
        </p>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setOpen(true)}
          >
            Sync CRM
          </button>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => navigate("/signin")}
          >
            Next step
          </button>
        </div>
      </div>

      {/* select user_type modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Select user type
                      </Dialog.Title>
                      <div className="mt-2">
                        <select
                          id="location"
                          name="location"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue="Location"
                          onChange={(e) => setUserType(e.target.value)}
                        >
                          <option>Location</option>
                          <option>Company</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => {
                        const data = {
                          user_type: userType,
                          user_id: userId,
                        }
                        axios
                          .put(`${BASE_URL}/users/user-type`, data)
                          .then((res) => {
                            if (res.data.success === "success") {
                              window.location =
                                "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&client_id=651ceefb6d2a5a3987c2ae97-lwau8qyx&redirect_uri=https://app.nabl.ai/redirect&scope=businesses.readonly%20businesses.write%20calendars.readonly%20calendars/groups.readonly%20calendars/groups.write%20calendars/events.readonly%20calendars/events.write%20conversations.readonly%20conversations.write%20conversations/message.readonly%20conversations/message.write%20campaigns.readonly%20contacts.readonly%20contacts.write%20locations.readonly%20locations/customValues.readonly%20locations/customValues.write%20locations/customFields.readonly%20locations/customFields.write%20opportunities.readonly%20opportunities.write%20links.readonly%20workflows.readonly%20forms.readonly%20users.readonly%20users.write%20surveys.readonly%20locations/tags.readonly%20locations/tags.write%20locations/templates.readonly%20locations/tasks.readonly%20medias.readonly%20medias.write%20oauth.readonly%20oauth.write%20snapshots.readonly%20links.write%20forms.write%20businesses.readonly%20businesses.write%20calendars.readonly%20calendars/events.readonly%20calendars/events.write%20conversations.readonly%20conversations.write%20conversations/message.readonly%20conversations/message.write%20campaigns.readonly%20contacts.readonly%20contacts.write%20locations.readonly%20locations/customValues.readonly%20locations/customValues.write%20locations/customFields.readonly%20locations/customFields.write%20opportunities.readonly%20opportunities.write%20links.readonly%20workflows.readonly%20forms.readonly%20users.readonly%20users.write%20surveys.readonly%20locations/tags.readonly%20locations/tags.write%20locations/templates.readonly%20locations/tasks.readonly"
                            }
                          })
                          .catch((error) => {
                            console.error("Error:", error)
                          })
                      }}
                    >
                      Sync
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
