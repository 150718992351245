import { useEffect, useState, useRef, Fragment } from "react"
import { Dialog, Transition, Switch } from "@headlessui/react"
import { RingLoader } from "react-spinners"
import axios from "axios"
import { BASE_URL } from "../../../utils/Config"
import axiosInstance from "../../../utils/AxiosInstance"
import { Tooltip } from "../../../components/Tooltip"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const override = {
  borderColor: "#2317e6",
  zIndex: 31,
}

export const Account = () => {
  const [loading, setLoading] = useState(false)
  const id = Number(localStorage.getItem("id"))
  const [locations, setLocations] = useState([])
  const [userType, setUserType] = useState("Location")
  const [open, setOpen] = useState(false) // open modal to select user_type
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/locations/profile`,
      params: {
        userId: id,
      },
    })
      .then((res) => {
        setLocations(res.data.locations)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })

    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="divide-y divide-white/5">
        <div className="flex justify-end m-4">
          <Tooltip
            message="This will prompt to sync HighLevel via Oauth"
            className="w-max right-0 -top-9"
          >
            <button
              type="button"
              className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-2"
              onClick={() => setOpen(true)}
            >
              Sync CRM
            </button>
          </Tooltip>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 pb-16 sm:px-6 lg:px-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-4">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6"
                      >
                        Source
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                      >
                        Account name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                      >
                        Active sync
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
                    {locations.length > 0 &&
                      locations.map((item) => (
                        <tr key={item.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 dark:text-gray-300 sm:pl-6">
                            HighLevel
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                            {item?.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                            <Switch
                              checked={item.active}
                              onChange={(e) => {
                                const data = {
                                  id: item.id,
                                  status: e,
                                }
                                axiosInstance({
                                  method: "put",
                                  url: `${BASE_URL}/locations/active`,
                                  data: data,
                                })
                                  .then((res) => {
                                    if (res.data.msg === "success") {
                                      axiosInstance({
                                        method: "get",
                                        url: `${BASE_URL}/locations/profile`,
                                        params: {
                                          userId: id,
                                        },
                                      })
                                        .then((res) => {
                                          setLocations(res.data.locations)
                                        })
                                        .catch((err) => {
                                          console.log(err)
                                        })
                                        .finally(() => {
                                          setLoading(false)
                                        })
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err)
                                  })
                              }}
                              className={classNames(
                                item.active
                                  ? "bg-indigo-600"
                                  : "bg-gray-200 dark:bg-gray-600",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  item.active
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-gray-200 shadow ring-0 transition duration-200 ease-in-out"
                                )}
                              />
                            </Switch>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* loading spinner */}
      {loading && (
        <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
          <RingLoader
            color="#2317e6"
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {/* end loading spinner */}

      {/* select user_type modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Select user type
                      </Dialog.Title>
                      <div className="mt-2">
                        <select
                          id="location"
                          name="location"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue="Location"
                          onChange={(e) => setUserType(e.target.value)}
                        >
                          <option>Location</option>
                          <option>Company</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => {
                        const data = {
                          user_type: userType,
                          user_id: id,
                        }
                        axios
                          .put(`${BASE_URL}/users/user-type`, data)
                          .then((res) => {
                            if (res.data.success === "success") {
                              window.location =
                                "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&client_id=651ceefb6d2a5a3987c2ae97-lwau8qyx&redirect_uri=https://app.nabl.ai/redirect&scope=businesses.readonly%20businesses.write%20calendars.readonly%20calendars/groups.readonly%20calendars/groups.write%20calendars/events.readonly%20calendars/events.write%20conversations.readonly%20conversations.write%20conversations/message.readonly%20conversations/message.write%20campaigns.readonly%20contacts.readonly%20contacts.write%20locations.readonly%20locations/customValues.readonly%20locations/customValues.write%20locations/customFields.readonly%20locations/customFields.write%20opportunities.readonly%20opportunities.write%20links.readonly%20workflows.readonly%20forms.readonly%20users.readonly%20users.write%20surveys.readonly%20locations/tags.readonly%20locations/tags.write%20locations/templates.readonly%20locations/tasks.readonly%20medias.readonly%20medias.write%20oauth.readonly%20oauth.write%20snapshots.readonly%20links.write%20forms.write%20businesses.readonly%20businesses.write%20calendars.readonly%20calendars/events.readonly%20calendars/events.write%20conversations.readonly%20conversations.write%20conversations/message.readonly%20conversations/message.write%20campaigns.readonly%20contacts.readonly%20contacts.write%20locations.readonly%20locations/customValues.readonly%20locations/customValues.write%20locations/customFields.readonly%20locations/customFields.write%20opportunities.readonly%20opportunities.write%20links.readonly%20workflows.readonly%20forms.readonly%20users.readonly%20users.write%20surveys.readonly%20locations/tags.readonly%20locations/tags.write%20locations/templates.readonly%20locations/tasks.readonly"
                            }
                          })
                          .catch((error) => {
                            console.error("Error:", error)
                          })
                      }}
                    >
                      Sync
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end select user_type modal */}
    </div>
  )
}
